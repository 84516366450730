<template>
  <div>
    <el-tooltip
      :content="props.isOnline ? 'Online' : 'Offline'"
      placement="top"
    >
      <span
        class="Badge"
        :class="{ 'Badge--online': props.isOnline }"
      />
    </el-tooltip>
  </div>
</template>

<script setup>
const props = defineProps({
  isOnline: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped>
.Badge {
  --size: 7px;

  @apply tw-bg-muted;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  display: block;
  border-radius: 50%;

  &--online {
    @apply tw-bg-success;
  }
}
</style>
